<template>

    <div  class="root-container" :style="{ 'height': appHeight }">
        <div class="popup-fullscreen-background" v-if="$store.state.maintenanceMode"></div>

        <transition-expand>
            <maintenance-mode-box v-if="$store.state.maintenanceMode" />
        </transition-expand>

        <router-view :class="{ 'blurred-element': $store.state.maintenanceMode }" />
    </div>
</template>

<script lang="js">
import MaintenanceModeBox from './components/MaintenanceModeBox.vue'
import TransitionExpand from './components/TransitionExpand.vue'
import VuexConfirmationModal from '@/components/admin/VuexConfirmationModal.vue'
import SignedInApp from '@/views/SignedInApp.vue';
import Login from '@/views/Login.vue';

export default {
    name: 'App',

    data() {
        return {
            appHeight: '100%',
            isOffline: !navigator.onLine,
            isCheckingLastDeplyment: false,
        }
    },

    methods: {
        async fetchWithTimeout(resource, options = {}) {
            const { timeout = 2000 } = options;
            const controller = new AbortController();
            const fetch_with_timeout_id = setTimeout(() => controller.abort(), timeout);

            const response = await fetch(resource, {options,signal: controller.signal});
            clearTimeout(fetch_with_timeout_id);

            return response;
        },
        async checkLastDeployment() {
            if (this.isOffline) {
                return;
            }
            if(this.isCheckingLastDeplyment){
                return;
            }
            this.isCheckingLastDeplyment = true;
            let lastDeployment;

            try{

                const res = await this.fetchWithTimeout('/last_deployment_time.txt?' + (new Date()).getTime());
                
                if (res && res.status === 200) {
                    lastDeployment = await res.text();
                } else {
                    console.log('Failed fetch of last deployment time.');
                    return;
                }
            }catch(error){
                this.isCheckingLastDeplyment = false;
                return;
            }finally{
                this.isCheckingLastDeplyment = false;
            }



            if (localStorage.lastRecordedDeployment === undefined) {
                // No last deployment found in local storage
                console.log('No record of last reployment found.');
                
                localStorage.lastRecordedDeployment = lastDeployment;
                return;
            }

            if (localStorage.lastRecordedDeployment !== lastDeployment) {
                // A new version of the app is available

                this.$store.commit('setUserShouldRefreshPage');
            }

            this.$store.tempLastRecordDeployment = lastDeployment
        },

        setAppHeight() {
            this.appHeight = `${window.innerHeight}px`;
        },

        registerFirstClick() {
            this.$store.commit('setWindowClicked');
        },

        setOnlineStatus() {
            this.isOffline = false;
            this.$store.commit('setOnline');
        },
        setOfflineStatus() {
            this.isOffline = true;
            this.$store.commit('setOffline');
        },
    },

    computed: {
        //
    },

    components: {
        SignedInApp,
        Login,
        TransitionExpand,
        MaintenanceModeBox,
        VuexConfirmationModal,
    },

    created() {
        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response.status == 503) {
                //console.log('503 intercepteds');

                this.$store.commit('setMaintenanceMode', error.response.data.maintenance_message === '' ? true : error.response.data.maintenance_message);
                /* if (response.message != undefined && response.message !== '') {
                    this.$store.commit('setMaintenanceMode', true);
                } else {
                    this.$store.commit('setMaintenanceMode', response.message);
                } */
            }

            return Promise.reject(error);
        });
    },

    mounted() {
        this.setAppHeight();
        window.addEventListener('resize', this.setAppHeight);
        window.addEventListener('click', this.registerFirstClick, { once: true });

        if (document.addEventListener) {
            document.addEventListener('fullscreenchange', this.setAppHeight, false);
            document.addEventListener('mozfullscreenchange', this.setAppHeight, false);
            document.addEventListener('MSFullscreenChange', this.setAppHeight, false);
            document.addEventListener('webkitfullscreenchange', this.setAppHeight, false);
        }

        this.checkLastDeployment();
        
        let secondsBetweenCheck = 60;

        if (process.env.VUE_APP_NEW_VERSION_CHECK_FREQUENCY === undefined) {
            console.log('Warning: VUE_APP_NEW_VERSION_CHECK_FREQUENCY not set in environment; using 60 second default');
        } else {
            secondsBetweenCheck = process.env.VUE_APP_NEW_VERSION_CHECK_FREQUENCY;
        }
        this.lastDeploymentIntervalId = setInterval(() => {
            if (!this.$store.getters.encourageRefresh) {
                this.checkLastDeployment();
            }
        }, secondsBetweenCheck * 1000);

        window.addEventListener('online', this.setOnlineStatus);
        window.addEventListener('offline', this.setOfflineStatus);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.setAppHeight);
        window.removeEventListener('click', this.registerFirstClick);
      window.removeEventListener('online', this.setOnlineStatus);
      window.removeEventListener('offline', this.setOfflineStatus);
    },
}
</script>

<style lang="scss">
@import "./src/assets/palettes/_standard.scss";

.blurred-element {
    /* Any browser which supports CSS3 */
    filter: blur(.5rem);
    transition: filter .2s;
}

@font-face {
    font-family: "OpenSans";
    src: url("./fonts/OpenSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url("./fonts/OpenSans-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "OpenSans";
    src: url("./fonts/OpenSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url("./fonts/OpenSans-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "OpenSans";
    src: url("./fonts/OpenSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url("./fonts/OpenSans-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "OpenSans";
    src: url("./fonts/OpenSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url("./fonts/OpenSans-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}

.root-container {
    height: 100vh; /* fallback for Js load */
}

// This is used to prevent click behind popup's like MaintenanceMode.vue
.popup-fullscreen-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999; // Exactly behind MaintenanceModeBox
}

</style>
